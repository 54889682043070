import { lazy } from "react";

const routePaths = [
  {
    title: "Login",
    path: "/",
    component: lazy(() => import("@/containers/SignIn/index.jsx")),
    exact: true,
  },
  {
    title: "Register",
    path: "/signup",
    component: lazy(() => import("@/containers/SignUp/index.jsx")),
    exact: true,
  },
  {
    title: "OTP Verification",
    path: "/otp-verification",
    component: lazy(() => import("@/containers/Otp/index.jsx")),
    exact: true,
  },
  {
    title: "Forgot Password",
    path: "/forgot-password",
    component: lazy(() => import("@/containers/ForgotPassword/index.jsx")),
    exact: true,
  },
  {
    title: "Reset Password",
    path: "/reset-password",
    component: lazy(() => import("@/containers/ResetPassword/index.jsx")),
    exact: true,
  },
];

export default routePaths;
