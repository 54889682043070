import { Suspense } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
// import { ConnectedRouter as Router } from "connected-react-router";

// import history from "@/store/history";
import routePaths from "@/constants/routePaths";
import Spinner from "./components/Loaders/Spinner";

const App = () => (
  <BrowserRouter>
    <Suspense fallback={<Spinner />}>
      <Switch>
        {routePaths.map((props, index) => (
          <Route key={index} {...props} />
        ))}
      </Switch>
    </Suspense>
    <div id="snackbar-container"></div>
  </BrowserRouter>
);

export default App;
